<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.devices')">
      <b-form @submit.prevent="searchFn">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3 my-1">
            {{ $t("message.type") }} :
            <br />
            <b-form-select v-model="searchData.modelType" :options="modelType" />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 my-1">
            {{ $t("message.name") }} :
            <br />
            <b-form-input v-model="searchData.name" />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 my-1">
            {{ $t("message.description") }} :
            <br />
            <b-form-input v-model="searchData.description" />
          </div>

          <div class="col-sm-12 col-md-6 col-lg-3 ">
            <br />
            <b-btn class="col-md-5 my-1 ml-1 mr-1 " variant="primary" type="submit">
              <i class="fa fa-search"></i>
              &nbsp;{{ $t("message.search") }}
            </b-btn>
            <b-btn class="col-md-5 my-1 ml-1 mr-1" variant="success" @click="showCreateModal()">
              <i class="fa fa-plus-square"></i>
              &nbsp;{{ $t("message.create") }}
            </b-btn>
          </div>
        </div>
      </b-form>
      <br />
      <div class="table-responsive">
        <table class="table2">
          <thead class="bg-primary" style="text-align: center">
            <tr>
              <th>{{ $t("message.type") }}</th>
              <th>{{ $t("message.modelType") }}</th>
              <th>{{ $t("message.name") }}</th>
              <th>{{ $t("message.description") }}</th>
              <th>{{ $t("message.createdAt") }}</th>
              <th>{{ $t("message.updatedAt") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody style="text-align: center">
            <template v-for="item in devices">
              <tr>
                <td>{{ item.type }}</td>
                <td>{{ item.modelType }}</td>
                <td>{{ item.name }}</td>
                <td style="text-align: center"><label style="width: 70px;">{{ item.description }}</label></td>
                <td style="text-align: center"><label style="width: 140px;">{{ item.createdAt | moment("YYYY-MM-DD HH:mm") }}</label></td>
                <td style="text-align: center"><label style="width: 140px;">{{ item.updatedAt | moment("YYYY-MM-DD HH:mm") }}</label></td>
                <td>
                  <b-btn variant="warning" @click="showEditModal(item)">แก้ไข</b-btn>
                </td>
              </tr>
            </template>
            <td></td>
          </tbody>
        </table>
      </div>
      <b-pagination size="md" :total-rows="totalRows" v-model="pagination" :per-page="itemPerPage" @input="pageChanged(pagination)"></b-pagination>
    </b-card>

    <b-modal ref="createModal" @ok="saveData()" :title="$t('message.create')">
      <div class="d-block">
        <div class="example-avatar">
          <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active"></div>
          <div class="avatar-upload" v-show="!edit">
            <div class="text-center p-2">
              <label for="avatar">
                <img :src="
                    files.length
                      ? files[0].url
                      : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'
                  " class="rounded" />
              </label>
            </div>
            <div class="text-center p-2">
              <file-upload
                extensions="gif,jpg,jpeg,png,webp"
                accept="image/png, image/gif, image/jpeg, image/webp"
                name="avatar"
                class="btn btn-primary"
                :custom-action="customUpload"
                :drop="!edit"
                v-model="files"
                @input-filter="inputFilter"
                @input-file="inputFile"
                ref="upload"
              >Upload Image</file-upload>
            </div>
          </div>

          <div class="avatar-edit" v-show="files.length && edit">
            <div class="avatar-edit-image" v-if="files.length">
              <img ref="editImage" :src="files[0].url" />
            </div>
            <div class="text-center p-4">
              <button type="button" class="btn btn-secondary" @click.prevent="$refs.upload.clear">Cancel</button>
              <button type="submit" class="btn btn-primary" @click.prevent="editSave">Save</button>
            </div>
          </div>
        </div>
        <table class="table2">
          <tr>
            <td>{{ $t("message.machineType") }}</td>
            <td>
              <b-form-select v-model="model.kiosktype" :options="kioskType" />
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.type") }}</td>
            <td>
              <b-form-select v-model="model.type" :options="type" />
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.modelType") }}</td>
            <td>
              <b-form-select v-model="model.modelType" :options="modelType" />
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.name") }}</td>
            <td>
              <input class="form-control" v-model="model.name" />
            </td>
          </tr>
          <tr>
            <td>{{ $t("message.description") }}</td>
            <td>
              <input class="form-control" v-model="model.description" />
            </td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import webServices from '../script'
import { DOMAIN } from '../app.config'
import { buildFormData } from '../lib/buildFormData'

import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'
export default {
  name: 'devices',
  components: {
    FileUpload
  },
  data() {
    return {
      formData: new FormData(),
      files: [],
      edit: false,
      cropper: false,
      totalRows: 1,
      pagination: 1,
      itemPerPage: 20,
      kioskType: [
        { value: 'washing', text: 'ฟาร์มเครื่องซักผ้า' },
        { value: 'vending', text: 'ตู้ขายน้ำอัติโนมัติ' },
        { value: 'oilpump', text: 'ตู้ขายน้ำมันออนไลน์' },
        { value: 'dw_vending', text: 'ตู้ขายน้ำดื่ม' }
      ],
      type: [
        { value: 'washer', text: 'เครื่องซักฝา' },
        { value: 'dryer', text: 'เครื่องอบผ้า' },
        { value: 'vending', text: 'เครื่องขายของ' },
        { value: 'oilpump', text: 'ตู้น้ำมัน' },
        { value: 'board', text: 'บอร์ดคอนโทรล' }
      ],
      modelType: [
        { value: 'TOP_DOOR_WASH', text: 'เครื่องซักฝาบน' },
        { value: 'SIDE_DOOR_WASH', text: 'เครื่องซักฝาหน้า' },
        { value: 'DRYERS', text: 'เครื่องอบผ้า' },
        { value: 'SELL_SLOT', text: 'เครื่องขายของ' },
        { value: 'MANUAL_OUTPUT', text: 'บอร์ดคอนโทรล' }
      ],
      searchData: {
        page: 1,
        rows: 20
      },
      devices: {},
      model: {}
    }
  },
  watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }
          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: 1 / 1,
            viewMode: 1
          })
          this.cropper = cropper
        })
      } else {
        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = false
        }
      }
    }
  },
  methods: {
    editSave() {
      this.edit = false
      let oldFile = this.files[0]
      let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type })
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true
      })
    },
    alert(message) {
      alert(message)
    },
    inputFile(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        this.$nextTick(function () {
          this.edit = true
        })
      }
      if (!newFile && oldFile) {
        this.edit = false
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Your choice is not a picture')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
    async customUpload(file, component) {
      this.formData.append('file', file.file)
    },
    searchFn() {
      this.$Progress.start()
      webServices
        .getDevices(this.searchData)
        .then(res => {
          this.$Progress.finish()
          this.totalRows = res.data.count
          this.devices = res.data.rows
        })
        .catch(err => {
          this.$Progress.fail()
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          console.log('error @getDevices')
          console.log(err)
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
        })
    },
    pageChanged(PageNum) {
      this.searchData.page = PageNum
      this.searchFn()
    },
    showCreateModal() {
      this.model = {}
      this.$refs.createModal.show()
    },
    showEditModal(data) {
      this.model = data

      if (data.image != null) {
        this.files[0] = { url: DOMAIN + '/' + data.image }
      } else {
        this.files[0] = {
          url: DOMAIN + '/public/images/products/tempproductimg.jpg'
        }
      }

      this.$refs.createModal.show()
    },
    saveData() {
      this.$Progress.start()

      console.log(this.model)

      buildFormData(this.formData, this.model)

      webServices
        .createOrUpdateDevice(this.formData)
        .then(res => {
          this.$Progress.finish()
          // console.log(res.data)
          if (res.data.success) {
            this.$toast.success({
              title: 'SUCCESS',
              message: 'Device has been created'
            })
            this.model = {}
            this.formData = new FormData()
            this.searchFn()
          } else {
            const errorText = {
              errorCode: res.data.errorCode,
              errorText: res.data.errorText
            }
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(errorText)
            })
          }
        })
        .catch(err => {
          this.$Progress.fail()
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          console.log(err)
          this.$toast.warn({
            title: 'ERROR',
            message: 'Can not create new device'
          })
        })
    }
  },
  mounted() {
    this.searchFn()
  }
}
</script>

<style lang="scss">
.example-avatar .avatar-upload .rounded {
  width: 300px;
  height: 300px;
}
.example-avatar .text-center .btn {
  margin: 0 0.5rem;
}
.example-avatar .avatar-edit-image {
  max-width: 100%;
}
.example-avatar .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.example-avatar .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>